<template>
	<div class="treatment-form flex-col flex-gap-small"
			 v-if="value">

		<!-- Treatment selected -->
		<div class="flex-item treatment-selected">
			<radio-toggle class="radio-group--inline"
										:id="`${inputId}_label`"
										:label="value.label"
										:value="value.selected"
										v-bind="$attrs"
										@input="update('selected', $event)"></radio-toggle>

			<span class="hint"
						v-if="value.hint"><em>{{ value.hint }}</em></span>
		</div>

		<div class="treatment-options flex-item--grow flex-col flex-gap-small"
				 v-if="parseInt(value.selected)">
			<!-- Treatment stop reason -->
			<div class="flex-item">
				<radio-group class="radio-group--inline"
										 :radios="stopItems"
										 label="Arrêt :"
										 :id="`${inputId}_stop`"
										 :value="value.stop"
										 v-bind="$attrs"
										 required
										 @input="update('stop', $event)"></radio-group>
			</div>

			<!-- Treatment dosage -->
			<div class="flex-item">
				<div class="input-group--inline required">
					<label for="dosage">Dosage :</label>
					<input type="text"
								 name="dosage"
								 :id="`${inputId}_dosage`"
								 :value="value.dosage"
								 v-bind="$attrs"
								 required
								 @input="update('dosage', $event)" />
				</div>
			</div>

		</div>
	</div>

</template>


<script>
import radioToggle from "@/components/radioToggle.vue";
import radioGroup from "@/components/radioGroup.vue";
import { slugify } from "@/libs/helpers";

export default {
	name: 'Treatment',
	inheritAttrs: false,
	components: { radioToggle, radioGroup },
	props: ['value'],

	data() {
		return {
			// stop radio items
			stopItems: [
				{
					value: "Non",
				},
				{
					value: "Résistance",
				},
				{
					value: "Perte de réponse",
				},
				{
					value: "Intolérance",
				},
			],
		}
	},

	computed: {
		inputId() {
			console.log("inputId", slugify(this.value.label));

			return slugify(this.value.label);
		},
	},

	methods: {
		update(key, value) {

			// Reset stop if not selected
			if (key === 'selected' && !parseInt(value.selected)) {
				this.value.stop = '';
				this.value.dosage = '';
			}

			// Emit to parent v-model
			if (value?.target) {
				// radios
				this.$emit('input', { ...this.value, [key]: value?.target.value })
			}
			else {
				// text input
				this.$emit('input', { ...this.value, [key]: value })
			}

		},
	},

}
</script>

<style lang="scss" scoped>
.hint {
	font-size: .875rem;
	color: #363636;
}

.treatment-form {
	padding-top: .5rem;
}

:deep(.treatment-selected) {
	.label {
		font-weight: 500;
	}
}

.treatment-options {
	padding: 0 1rem 1rem 1rem;
}
</style>